import "./App.css";
import Routes from "./routes";
import { useTranslation } from "react-i18next";
import { StyledEngineProvider } from "@mui/material";
import NavigationScroll from "./layouts/NavigationScroll";

function App() {
  const { t } = useTranslation("translation");


  return (
    <StyledEngineProvider injectFirst>
      <NavigationScroll>
      <div
        className="App"
        style={
          t("lang") === "عربي"
            ? { fontFamily: "Heebo " }
            : { fontFamily: "Tajawal" }
        }
        dir={t("lang") === "عربي" ? "ltr" : "rtl"}
      >
        <Routes />
      </div>
      </NavigationScroll>
    </StyledEngineProvider>
  );
}

export default App;
