import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import enLang from "./services/locales/en.json";
import arLang from "./services/locales/ar.json";
// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
import { BrowserRouter } from "react-router-dom";
// register Swiper custom elements
register();
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: localStorage.getItem("lang") || "en", // language to use
  resources: {
    en: {
      translation: enLang, // 'translation' is our custom namespace
    },
    ar: {
      translation: arLang,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);
