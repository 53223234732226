import { lazy } from "react";

import Loadable from "./Loadable.js";

const Home = Loadable(lazy(() => import("../pages/Home")));
const Container = Loadable(lazy(() => import("../layouts/Container.jsx")));
const About = Loadable(lazy(() => import("../pages/About")));
const Industries = Loadable(lazy(() => import("../pages/Industries")));
const Press = Loadable(lazy(() => import("../pages/Press")));
const PressChild = Loadable(lazy(() => import("../pages/PressChild")));
const Services = Loadable(lazy(() => import("../pages/Services")));
const ServicesChild = Loadable(lazy(() => import("../pages/ServicesChild")));
const Insights = Loadable(lazy(() => import("../pages/Insights")));
const InsightsChild = Loadable(lazy(() => import("../pages/InsightsChild")));
const E404 = Loadable(lazy(() => import("../pages/E404")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const AboutChild = Loadable(lazy(() => import("../pages/AboutChild")));

const MainRoutes = {
  path: "/",
  element: <Container />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "about",
      element: <About />,
    },
    {
      path: "about/:id",
      element: <AboutChild />,
    },
    {
      path: "industries",
      element: <Industries />,
    },
    {
      path: "press",
      element: <Press />,
    },
    {
      path: "/press/:id",
      element: <PressChild />,
    },
    {
      path: "services",
      element: <Services />,
    },
    {
      path: "/services/:id",
      element: <ServicesChild />,
    },
    {
        path: "insights",
        element: <Insights />,
    },
    {
      path: "/insights/:id",
      element: <InsightsChild />,
    },
    {
      path: "contact",
      element: <Contact />,
    },
    {
      path: "*",
      element: <E404 />,
    },
  ],
};

export default MainRoutes;
